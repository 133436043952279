import { graphql } from "gatsby";
import React from "react";
import Layout from "../components/Layout";
import { Heading, Text, Box, Link, Stack, StackDivider } from "@chakra-ui/react";
import SinglePageHeadingSection from "../components/SinglePageHeadingSection";
import { RichText } from "prismic-reactjs";
import { standardToName } from "../utils/standardToName";
import { htmlSerializer } from "../utils/htmlSerializer";
import { generateTableOfContent } from "../utils/generateTableOfContent";
import Container from "../components/PageContentContainer";
import Seo from "../components/SEO";

const Page = ({ data: queryResult }) => {
  const { data, uid } = queryResult.allPrismicTrainings.edges[0].node;
  const standard = uid.split("_")[2].split("-")[1];
  const tableOfContent = generateTableOfContent(data.content.richText);
  
  return (
    <Layout>
      <Seo title={data.title.text} />
      <SinglePageHeadingSection
        imgSrc={"/bgimage2.png"}
        title={data.title.text}
        subtitle={standardToName(standard)}
      />
      <Container>
        <Stack
          direction={"row"}
          divider={
            <StackDivider
              backgroundColor={"gray.500"}
              display={{ base: "none", lg: "block" }}
            />
          }
        >
          <Box flex={1} pe={"1.5rem"}>
            <RichText
              render={data.content.richText}
              htmlSerializer={htmlSerializer}
            />
          </Box>
          <Box
            width={"300px"}
            ps={"1.5rem"}
            position={"relative"}
            display={{ base: "none", lg: "block" }}
          >
            <Box position={"sticky"} top="1rem">
              <Heading as="h6" size="lg" color="#2b2f69">
                Table of Content{" "}
              </Heading>
              {tableOfContent}
            </Box>
          </Box>
        </Stack>
      </Container>
       <Box>
      <Box
        maxW="7xl"
        w={{ md: "3xl", lg: "4xl" }}
        mx={{ base: 3, lg: "auto" }}
        my={{ base: 3, lg: 6 }}
        bg={"#2b2f69"}
        boxShadow="xl"
        py={{ base: 3, lg: 6 }}
        px={{ base: 4, lg: 8 }}
        rounded={"md"}
        display={"flex"}
        flexDirection={{ base: "column", lg: "row" }}
        alignItems={"center"}
        justifyContent={{ lg: "space-between" }}
      >
        <Heading as="h3" color={"gray.100"} textAlign={"center"} flex={1}>
          Get {standard} Training from us
        </Heading>
        <Link
          display="block"
          px={5}
          py={3}
          border="solid transparent"
          fontWeight="bold"
          rounded="md"
          shadow="md"
          bg="white"
          textDecoration="none"
          href={"#contact-us"}
          mt={3}
        >
          Apply Now
        </Link>
      </Box>
    </Box>
    </Layout>
  );
};

export const query = graphql`
  query TrainingAwarenessPageData($slug: String) {
    allPrismicTrainings(filter: { uid: { eq: $slug } }) {
      edges {
        node {
          data {
            content {
              richText
            }
            title {
              text
            }
          }
          uid
        }
      }
    }
  }
`;

export default Page;
